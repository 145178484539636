import React, { useState } from 'react'
import Layout from '../components/Layout';
import PropTypes from 'prop-types';
import '../style/contact-us.sass';
import { graphql } from 'gatsby';
import { GatsbyImage , getImage } from "gatsby-plugin-image";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import useWindowSize from '../hooks/useWindowSize';
import UseGoToSection from '../components/UseGoToSection';

delete L.Icon?.Default?.prototype._getIconUrl;

L.Icon?.Default?.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: ('/img/map-icon.png'),
  shadowUrl: markerShadow,
});

const SetMapView = ({ coordinates }) => {
  const map = useMap();
  map.setView(coordinates);
  return null;
};

const Section1 = ({ content1 }) => {
  const { width } = useWindowSize();
  // Initialize state with the first location's coordinates
  const [selectedCoordinates, setSelectedCoordinates] = useState([
    content1.array[0]?.coordinates?.latitude || 28.41479115207311,
    content1.array[0]?.coordinates?.longitude || 77.35657448632696
  ]);

  return (
    <div className={`section1-main-container section1 ${width > 768 ? 'fit-page': ''}`}>
      <div className='section1-container'>
        <div className='section1-image'>
          <MapContainer style={{ height: '27rem', width: '100%' }} center={selectedCoordinates} zoom={13} scrollWheelZoom={false}>
            <SetMapView coordinates={selectedCoordinates} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {content1.array.map((item, index) => {
              let coordinates = []
              if(item?.coordinates?.latitude) {
                coordinates.push(item.coordinates.latitude)
              }
              if(item?.coordinates?.longitude) {
                coordinates.push(item.coordinates.longitude)
              }
              return coordinates?.length && (
              <Marker key={index} position={coordinates}>
                <Popup>{item.details}</Popup>
              </Marker>
            )})}
          </MapContainer>
        </div>
        <div className='section1-address'>
          <p className='has-text-weight-bold section1-address-title' style={{color: content1.titleColor}}>{content1.title}</p>
          {content1.array.map((item,i) => {
            const logo = getImage(item.logo)
            return(
              <a href={item.path} target="_blank" rel="noopener noreferrer" style={{color: "black", cursor: "pointer"}}>
                <div key={i} className='section1-address-sub'>
                  <div className='address-logo-bg'>
                    {logo ? (
                    <GatsbyImage image={logo} alt='Loading...' className='address-logo'/>
                    ) : (
                    <img src={item.logo} alt='Loading...' className='address-logo'/>
                    )}
                  </div>
                  <p className= { i === 1 ? 'section1-address-sub-p' : 'section1-address-sub-p'} 
                  onClick={() => {if(item && item.coordinates && item.coordinates.latitude && item.coordinates.longitude) { return setSelectedCoordinates([item.coordinates.latitude, item.coordinates.longitude])}}}>{item.details}</p>
                </div>
              </a>
            )
          })} 
        </div>
      </div>
    </div>
  )
}

const TitleFragment = ({ fragment, color }) => (
  <span className='has-text-weight-medium has-text-weight-bold set-title' style={{ color }}>{fragment + " "}</span>
);

const Section2 = ({ content2 }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const errors = {};
    const emailRegex =  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/; // Simple email regex
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!emailRegex.test(formData.email)) errors.email = "Enter a valid Email";
    // if (!formData.phone) errors.phone = "Phone number is required";
    // if (!formData.subject) errors.subject = "Subject is required";
    // if (!formData.message) errors.message = "Message is required";
    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      // Sending email using EmailJS
      e.preventDefault()
      try {
        const response = await fetch('/api/sendEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
    
        const result = await response.json();
        if (response.status === 200) {
          setIsSubmitted(true);
          console.log('Email sent successfully:', result);
        } else {
          console.log('Email sending failed:', result);
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
    }
  };

  return (
    <div className='section2-main-container section2'>
      <div className='section2-container'>
        <div className='section2-container-msg-title'>
          {content2.title.map((value, i) => (
            <TitleFragment key={i} {...value} />
          ))}
          <div className='section2-container-msg-line-con'>
            <p className='section2-container-msg-title-line'></p>
          </div>
        </div>
        <div className='section2-container-form'>
          <p>{content2.info}</p>
          {isSubmitted ? (<p className='form-note form-note-success'>Thank you! Your message has been sent</p>) : (
            <form onSubmit={handleSubmit} noValidate>
              <div className='section2-container-form-sub'>
                <div className='section2-container-form-sub-1'>
                  <label htmlFor='name'>Name *</label>
                  <input 
                    type='text' 
                    id='name' 
                    name='name' 
                    className={`input-field-1 ${formErrors.name ? 'input-error' : ''}`}
                    value={formData.name} 
                    onChange={handleInputChange} 
                    required 
                  />
                  {formErrors.name && <span className='error-text'>{formErrors.name}</span>}
                </div>
                <div className='section2-container-form-sub-1'>
                  <label htmlFor='email'>Email *</label>
                  <input 
                    type='email' 
                    id='email' 
                    name='email' 
                    className={`input-field-1 ${formErrors.email ? 'input-error' : ''}`} 
                    value={formData.email} 
                    onChange={handleInputChange} 
                    required 
                  />
                  {formErrors.email && <span className='error-text'>{formErrors.email}</span>}
                </div>
                <div className='section2-container-form-sub-1'>
                  <label htmlFor='subject'>Subject</label>
                  <input 
                    type='text' 
                    id='subject' 
                    name='subject' 
                    className={`input-field-1 ${formErrors.subject ? 'input-error' : ''}`} 
                    value={formData.subject} 
                    onChange={handleInputChange} 
                    required 
                  />
                  {formErrors.subject && <span className='error-text'>{formErrors.subject}</span>}
                </div>
                <div className='section2-container-form-sub-1'>
                  <label htmlFor='phone'>Phone</label>
                  <input 
                    type='tel' 
                    id='phone' 
                    name='phone' 
                    className={`input-field-1 ${formErrors.phone ? 'input-error' : ''}`} 
                    value={formData.phone} 
                    onChange={handleInputChange} 
                    required 
                  />
                  {formErrors.phone && <span className='error-text'>{formErrors.phone}</span>}
                </div>
              </div>
              <div className='section2-container-form-sub-2'>
                <label htmlFor='message'>Message</label>
                <textarea 
                  id='message' 
                  name='message' 
                  className={`input-field-2 ${formErrors.message ? 'input-error' : ''}`} 
                  value={formData.message} 
                  onChange={handleInputChange} 
                  required 
                />
                {formErrors.message && <span className='error-text'>{formErrors.message}</span>}
              </div>
              <button type='submit' className='form-button' style={{ background: content2.buttonColor }}>
                {content2.buttonText}
              </button>
            </form>
          )}
          <p className='form-note'>Note: {content2.note}</p>
        </div>
      </div>
    </div>
  );
}

export const ContactUsTemplate = ({section1 , section2}) => {
    return (
        <div>
          <Section1 content1 = {section1}/>
          <Section2 content2 = {section2}/>
          <UseGoToSection />
        </div>
    )
}

const ContactusPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
    return (
        <Layout>
            <ContactUsTemplate 
              section1= {frontmatter.section1.desktop}
              section2= {frontmatter.section2}/>
        </Layout>
    )
}

ContactUsTemplate.propTypes = {
  section1: PropTypes.shape({
    desktop: PropTypes.object.isRequired,
    mobile: PropTypes.object.isRequired,
  }).isRequired,
  section2: PropTypes.shape({
    title: PropTypes.arrayOf(
      PropTypes.shape({
        fragment: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      })
    ).isRequired,
    info: PropTypes.string.isRequired,
    array: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        for: PropTypes.string.isRequired,
        pattern: PropTypes.string.isRequired,
        inputmode: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
    msg: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonColor: PropTypes.string.isRequired,
    note: PropTypes.string.isRequired,
  }).isRequired,
}    

ContactusPage.propTypes = {
  data: PropTypes.shape({
      markdownRemark: PropTypes.object,
  }),
};

export default ContactusPage

export const pageQuery = graphql`
  query ContactusPage {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-us" } }) {
      frontmatter {
        section1 {
          desktop {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            video
            title
            array {
              logo {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              details
              coordinates {
                latitude
                longitude
              }
              path 
            }
            titleColor
          }
          mobile {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            video
            title
            array {
              logo {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
              details
              coordinates {
                latitude
                longitude
              }
              path 
            }
            titleColor
          }
        }
        section2 {
          title {
            fragment
            color
          }
          info
          array {
            type
            for
            pattern
            inputmode
            title
          }
          msg
          buttonText
          buttonColor
          note
        }
      }
    }
  }
`;